import React, { useState, useEffect } from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import VerticalBanner from '../svg/VerticalBanner';
import Countdown from 'react-countdown';
import backgroundImage from '../css/images/working-picture.png'; 
import axios from 'axios'; // Import Axios for making HTTP requests
import ContactForm from '../components/ContactForm';

const Index = () => {
  const targetDate = new Date('2024-03-15T00:00:00');
  const [timeRemaining, setTimeRemaining] = useState(targetDate - new Date());

  const sectionStyle = {
    // backgroundImage: `url(${process.env.PUBLIC_URL}/background-image.jpg)`,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const textContainerStyle = {
    background: 'rgba(0, 0, 0, 0.7)', // Black with 0.7 opacity
    padding: '20px', // Adjust padding as needed
    borderRadius: '8px', // Optional: Add rounded corners
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return  <p className="mt-8">
      <a href="https://froot.shop">
        <Button size="xl">Shop froot™</Button>
      </a>
    </p>;
    } else {
      // Render a countdown
      const style = {
        fontSize: '2em', // Adjust the font size as needed
        color: 'black',
        fontFamily: 'monospace', // Use a monospaced font for the digital clock-like styling
        backgroundColor: 'white', // White background for each digit
        padding: '0.2em', // Add some padding for better spacing
        borderRadius: '0.2em', // Add rounded corners for a polished look
        margin: '0.2em', // Add margin for spacing between digits
      };
  
      return (
        <span style={style}>
          {days} days {hours} hours {minutes} minutes {seconds} seconds
        </span>
      );
    }
  };

  //Contact us form

  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const sgMail = require('@sendgrid/mail')
      // sgMail.setApiKey(process.env.SENDGRID_API_KEY)
      sgMail.setApiKey("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")
      const msg = {
        to: 'test@example.com', // Change to your recipient
        from: 'info@theparse.co.za', // Change to your verified sender
        subject: 'Sending with SendGrid is Fun',
        text: 'and easy to do anywhere, even with Node.js',
        html: '<strong>and easy to do anywhere, even with Node.js</strong>',
      }
      sgMail.send(msg)
      .then(() => {
        console.log('Email sent')
  })
      setFormData({ name: '', email: '' }); // Reset the form after successful submission
    } catch (error) {
      console.error(error)
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(targetDate - new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return (
    <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Premium Tech Accessories
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Welcome to froot™, where innovation meets accessibility in the heart of South Africa's tech realm. Nestled at the forefront of digital evolution, froot™ emerges as a beacon of cutting-edge technology and unparalleled service. With an unwavering commitment to pioneering advancements and a vision to revolutionize the tech landscape, froot™ aspires to be the premier online destination for all things tech in South Africa. Join us on a journey where the boundaries of possibility are constantly pushed, and where technology meets convenience with every click.
          </p>
          <p className="mt-8 md:mt-12">
            <a href="https://froot.shop">
              <Button size="lg">Shop froot™</Button>
            </a>
          </p>
          <p className="mt-4 text-gray-600">Premium Tech Accessories.</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="ourstory" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
      <h2 className="text-3xl lg:text-5xl font-semibold">Our Story</h2>
      <div className="m-10">
        <p>
          froot™ isn't simply a brand; it's a pledge to render accessories & technology more accessible, innovative, and cost-effective. Originating in 2022, our journey commenced with a visionary group striving to enhance tech encounters. Initially focusing on cellphone covers, we rapidly diversified our lineup to encompass a broad spectrum of indispensable tech accessories, all at competitive prices. Our growth hinges on an unwavering commitment to delivering top-tier tech accessories and beyond, ensuring you access the finest innovations.
        </p>
      </div>
        <h2 className="text-3xl lg:text-5xl font-semibold">Why Choose froot™?</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Hassle-Free Shopping</p>
              <p className="mt-4">
                Our user-friendly website and streamlined checkout process make shopping with us a breeze. Plus, we offer secure payment options and fast, reliable shipping to get your items to you in no time.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Style Meets Functionality</p>
              <p className="mt-4">
                Express your unique style with our trendy and chic accessories. We offer a wide range of designs, colors, and materials to match your personal aesthetic.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Premium Quality</p>
              <p className="mt-4">
                We believe that top-notch accessories shouldn't break the bank. Our products are carefully selected to ensure they meet the highest quality standards while remaining budget-friendly.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section id="ourstory" style={sectionStyle} className="py-20 lg:pb-40 lg:pt-48 text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl lg:text-6xl font-semibold mb-6">Partnering with YOU.</h2>
        <div className="mx-6 lg:mx-20" style={textContainerStyle}>
          <p className="text-lg lg:text-xl leading-relaxed text-white">
            At froot™, we're passionate about collaborating with local innovators. We welcome creative minds from within our South African community to reach out and partner with us. Together, we can explore new ideas and bring innovative solutions to the forefront, ensuring that our range of accessories reflects diverse perspectives and locally inspired ingenuity.
          </p>
        </div>
        <div className="mt-12">
          <h2 className="text-4xl lg:text-6xl font-semibold mb-6 text-blue-900" style={{ backgroundColor: 'white' }}>We're launching soon....</h2>
          <Countdown 
            date={targetDate}
            renderer={renderer}   
          />
        </div>
      </div>
    </section>
   
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Phone Cases, Screen Protectors, and Charging Solutions: Protect and Power Up Your Device</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          Froot's commitment to excellence shines through in its range of phone cases, screen protectors, and charging solutions. Crafted with premium materials and meticulous attention to detail, these accessories epitomize durability and style. Froot's phone cases offer a perfect blend of protection and aesthetics, ensuring your device remains both secure and visually stunning. 
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed"> 
            Step into our digital showroom to explore a diverse array of cutting-edge products designed to elevate your tech game. From the latest smartphones accessories to smart home solutions and beyond, our eCommerce store offers a treasure trove of gadgets that cater to every need and desire.
          </p>
            <p className="mt-8">
            <a href="https://froot.shop">
              <Button size="xl">Coming Soon</Button>
            </a>
          </p>
        </div>
      }
      secondarySlot={<VerticalBanner />}
    />
    {/* <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Phone Cases, Screen Protectors, and Charging Solutions: Protect and Power Up Your Device
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            At froot™, we understand that your smartphone isn't just for calls; it's a gateway to immersive experiences. Immerse yourself in music, podcasts, and calls with our high-quality earphones and headsets. Whether you prefer the convenience of wireless options or the reliability of wired ones, our products are designed to deliver exceptional audio quality, ensuring a seamless listening experience.
          </p>
          <p className="mt-8 text-xl font-light leading-relaxed">
            But we don't stop at audio. We offer a range of smart accessories to elevate your tech game. From stylish smartwatches that keep you connected and organized to fitness trackers that help you stay on top of your health and fitness goals, we bring you the latest gadgets to complement your lifestyle. With froot™™, you can immerse, communicate, and elevate your tech experience to a whole new level.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}
    <SplitSection
      primarySlot={
        <div class="lg:pr-32 xl:pr-48">
        <h3 class="text-3xl font-semibold leading-tight">
          Follow us on Social Media
        </h3>
        {/* <p class="mt-8 text-xl font-light leading-relaxed">
          Elevate your tech game with our curated selection of top-notch accessories designed to enhance your digital experience. From sleek phone cases that marry style with protection to ergonomic laptop stands ensuring comfort during those marathon work sessions, we've got you covered. Discover charging solutions that keep your devices powered on the go.
        </p> */}
        
        <div class="mt-8 flex space-x-4">
          <a href="https://www.instagram.com/froot_za/?fbclid=IwAR2nfPcrLn-1X1cJ4aT_mo3n_HtICVgx6kzWbq5uBmOzRV1j7Ckohy22qqo" target="_blank" rel="noopener noreferrer" class="text-gray-800 hover:text-gray-600">
            <i class="fab fa-instagram fa-2x"></i>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61553120834958" target="_blank" rel="noopener noreferrer" class="text-gray-800 hover:text-gray-600">
            <i class="fab fa-facebook-square fa-2x"></i>
          </a>
        </div>
      </div>
      }
      secondarySlot={<SvgCharts />}
    />
    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Our customers get results</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Customer satisfaction" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="On-time delivery" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}
    {/* <section id="testimonials" className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What customers are saying</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section> */}
    
  </Layout>
  );
};

export default Index;